import { makeStyles } from '@material-ui/core/styles'
import { BREAKPOINTS, FONTS, COLORS } from '../../constants'

export const styles = makeStyles(theme => ({
  countText: {
    // display: 'none',
    display: 'block',
    fontFamily: FONTS.Graphik.GraphikRegular,
    fontSize: '1rem',
    fontWeight: 400,
    color: COLORS.ashGrey,
    textTransform: 'uppercase',
    margin: 0,
  },
  [`@media (min-width: ${BREAKPOINTS.md}px)`]: {
    countText: {
      display: 'block',
      fontFamily: FONTS.Graphik.GraphikRegular,
      fontSize: '1rem',
      fontWeight: 400,
      color: COLORS.ashGrey,
      textTransform: 'uppercase',
      margin: 0,
    },
  },
}))

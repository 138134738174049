import * as React from 'react'
import { styles } from './styles'
import { withTranslation } from 'react-i18next'
import { TFunction } from 'i18next'

interface BrandsCountProps {
  count: Number
  t: TFunction
}

const CHRBrandsCount = ({ t, count, ...props }: BrandsCountProps) => {
  const classes = styles()

  return (
    <h2 className={classes.countText}>
      {count} {t('shared.brandsAndRetailers')}
    </h2>
  )
}

export default withTranslation()(CHRBrandsCount)

import * as React from 'react'
import { styles } from './styles'
import { withTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { Typography } from '@material-ui/core'

// if (typeof window !== `undefined`) {
//   require('smooth-scroll')('a[href*="#"]')
// }

interface BrandsGroupItemProps {
  children: React.ReactNode
  id: string
  name: string
  suffix: string
  t: TFunction
}

const CHRBrandsGroupItem = ({
  children,
  id,
  name,
  suffix,
  t,
  ...props
}: BrandsGroupItemProps) => {
  const classes = styles()

  return (
    <>
      <Typography id={id} variant="h1" component="h2" className={classes.title}>
        {name} <span className={classes.suffix}>{suffix}</span>
      </Typography>
      <div className={classes.brandsContainer}>{children}</div>
    </>
  )
}

export default withTranslation()(CHRBrandsGroupItem)
